import React, { useState, useEffect } from 'react'
import './style.less'
import logo from '../../images/header/logo.png'
import logogreen from '../../images/header/logo.png'
import Button from '../Button/Button'
import useWindowDimensions from '../../useWindowDimensions'

const Header = (props) => {
	const { height, width } = useWindowDimensions()
	const [isSticky, setSticky] = useState(false)
	const [isList, setisList] = useState(false)
	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', () => handleScroll)
		}
	}, [])

	const handleScroll = () => {
		try {
			if (width < 1023) {
				if (window.pageYOffset > 0) {
					setSticky(true)
				} else {
					setSticky(false)
				}
			} else {
				if (window.pageYOffset > 10) {
					setSticky(true)
				} else {
					setSticky(false)
				}
			}
		} catch (error) {}
	}

	// return <h1>Header</h1>;

	return (
		<>
			{width >= 1024 ? (
				<div
					className={`header  d-f f-c jc-sb a-c ${isSticky ? 'sticky' : null}`}
				>
					<div className='header d-f jc-sb a-c'>
						<div to='/'>
							<img src={isSticky ? logogreen : logo} />
						</div>
						<div className='d-f'>
							<div onClick={props.homeBtnClick} className='title c-p'>
								Home
							</div>
							<div onClick={props.productBtnClick} className='title c-p'>
								Product
							</div>

							<div onClick={props.showModel} className='title c-p'>
								Contact
							</div>
						</div>
						<div>
							<Button onClick={props.showModel} title='Request Demo' />
						</div>
					</div>
				</div>
			) : (
				<div className={`header  ${isSticky ? 'sticky' : null}`}>
					<div className='d-f a-c jc-sb'>
						<div to='/'>
							<img src={logogreen} className='fluid mblogo' />
						</div>

						<div>
							<button
								aria-label='button'
								onClick={() => setisList(!isList)}
								className={isList ? 'opened menu' : 'menu'}
							>
								<svg width='70' height='50' viewBox='0 0 100 100'>
									<path
										className='line line1'
										fill='#000000'
										d='M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058'
									/>
									<path
										fill='#000000'
										className='line line2'
										d='M 20,50 H 80'
									/>
									<path
										fill='#000000'
										className='line line3'
										d='M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942'
									/>
								</svg>
							</button>
						</div>
					</div>

					{isList ? (
						<div className='headerMobileView'>
							<div
								onClick={() => {
									props.homeBtnClick()
									setisList(false)
								}}
								className='title c-p'
							>
								Home
							</div>
							<div
								onClick={() => {
									props.productBtnClick()
									setisList(false)
								}}
								className='title c-p'
							>
								Product
							</div>

							<div
								onClick={() => {
									setisList(false)
									props.showModel()
								}}
								className='title c-p'
							>
								Contact
							</div>
						</div>
					) : null}
				</div>
			)}
		</>
	)
}

export default React.memo(Header)
