import React from 'react'
import './style.less'

const ListItem = (props) => {
	//console.log("==>", props.fournotfonund);

	if (props.gif && props.mobile) {
		return (
			<div className='swiper-slide'>
				<div className='d-f f-c'>
					<div className='videoContainer'>
						<img src={props.gif} className='img-fluid' />
					</div>
					<div>
						<div className='itemTitle'>{props.title}</div>
						<div className='itemDesc'>{props.desc}</div>
						{props.desc2 && <div className='itemDesc'>{props.desc2}</div>}
						{props.desc3 && <div className='itemDesc'>{props.desc3}</div>}
						{props.desc4 && <div className='itemDesc'>{props.desc4}</div>}
					</div>
				</div>
			</div>
		)
	}

	if (props.right) {
		return (
			<div
				className={`itemContainer ${props.selected}  right`}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
			>
				<div className='itemTitle'>{props.title}</div>
				<div className='itemDesc'>{props.desc}</div>
				{props.desc2 && <div className='itemDesc'>{props.desc2}</div>}
				{props.desc3 && <div className='itemDesc'>{props.desc3}</div>}
				{props.desc4 && <div className='itemDesc'>{props.desc4}</div>}
			</div>
		)
	}

	return (
		<div
			className={`itemContainer ${props.selected} ${
				props.right ? 'right' : 'left'
			}`}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			<div className='itemTitle'>{props.title}</div>
			<div className='itemDesc'>{props.desc}</div>
			{props.desc2 && <div className='itemDesc'>{props.desc2}</div>}
			{props.desc3 && <div className='itemDesc'>{props.desc3}</div>}
			{props.desc4 && <div className='itemDesc'>{props.desc4}</div>}
		</div>
	)
}

export default React.memo(ListItem)
