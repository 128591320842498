import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import transperantlogo from "../../images/logo.png";
import "./style.less";
import loader from "../../images/loader.gif";
import InputText from "../InputText/InputText";
import { Col, Row } from "react-bootstrap";
import Button from "../Button/Button";

const ModelForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [firstNameerr, setfirstNameerr] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameerr, setlastNameerr] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyerr, setcompanyerr] = useState("");
  const [industry, setIndustry] = useState("");
  const [email, setEmail] = useState("");
  const [emailIderr, setemailIderr] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactNumbererr, setcontactNumbererr] = useState("");
  const [country, setCountry] = useState("");
  const [other, setOther] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    var formIsValid = true;

    if (document.getElementById("check").checked) {
      if (typeof contactNumber !== "undefined") {
        if (!contactNumber.match(/^[0-9]{10}$/)) {
          formIsValid = false;
          setcontactNumbererr("*Please enter valid contact number.");
        } else {
          setcontactNumbererr("");
        }
      }

      if (!firstName) {
        formIsValid = false;
        setfirstNameerr("*Please enter your First Name.");
      } else {
        setfirstNameerr("");
      }

      if (!lastName) {
        formIsValid = false;
        setlastNameerr("*Please enter your Last Name.");
      } else {
        setlastNameerr("");
      }

      if (!companyName) {
        formIsValid = false;
        setcompanyerr("*Please enter your Company Name.");
      } else {
        setcompanyerr("");
      }
      if (typeof email !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(email)) {
          formIsValid = false;
          setemailIderr("*Please enter valid email-Id.");
        } else {
          setemailIderr("");
        }
      }

      if (formIsValid) {
        setError("");
        setLoading(true);
        try {
          var formData = new FormData(this);
          formData.append("firstName", firstName);
          formData.append("lastName", lastName);
          formData.append("companyName", companyName);
          formData.append("industry", industry);
          formData.append("email", email);
          formData.append("contactNumber", contactNumber);
          formData.append("country", country);
          formData.append("other", other);

          await fetch(
            "https://script.google.com/macros/s/AKfycbzdok8lD_AYttR5tpCHFiBD1I6eVIcwawxevlYDjwDGstsBkGJOYFDAJi-R5zvsDe-E/exec",
            {
              method: "POST",
              mode: "no-cors",
              body: formData,
            }
          );
          //setSuccess('Form Submitted')
          setFirstName("");
          setLastName("");
          setCompanyName("");
          setEmail("");
          setContactNumber("");
          setCountry("");
          setOther("");
          setIndustry("");
          setLoading(false);
          props.hide();
        } catch (error) {
          setLoading(false);
          setError("Something went Wrong");
        }
      }
    } else {
      setError("Please check the consent");
    }
  };

  useState(() => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setIndustry("");
    setEmail("");
    setContactNumber("");
    setCountry("");
    setOther("");
    setError("");
    //setSuccess('')
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.hide}
    >
      <Modal.Header>
        <div className="imageContainer">
          <img src={transperantlogo} className="img-fluid" />
        </div>
        <div onClick={props.hide} className="c-p">
          {/* <img src={cross} className='img-fluid' /> */}
          <div className="cross"></div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="title">Yes, I want a Demo!</div>
        <Row>
          <Col className="pd-2" lg={6}>
            <InputText
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              title="First Name*"
            />
            <div className="err">{firstNameerr}</div>
          </Col>
          <Col className="pd-2" lg={6}>
            <InputText
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              title="Last Name*"
            />
            <div className="err">{lastNameerr}</div>
          </Col>
          <Col className="pd-2" lg={12}>
            <InputText
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              title="Company Name*"
            />
            <div className="err">{companyerr}</div>
          </Col>
          <Col className="pd-2" lg={12}>
            <InputText
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              title="Industry"
            />
          </Col>
          <Col className="pd-2" lg={12}>
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              title="Email*"
            />
            <div className="err">{emailIderr}</div>
          </Col>
          <Col className="pd-2" lg={6}>
            <InputText
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              type="tel"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              title="Contact No.*"
            />
            <div className="err">{contactNumbererr}</div>
          </Col>
          <Col className="pd-2" lg={6}>
            <InputText
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              title="Country"
            />
          </Col>
          <Col className="pd-2" lg={12}>
            <InputText
              value={other}
              onChange={(e) => setOther(e.target.value)}
              title="Please mention requirements (if any)"
            />
          </Col>
        </Row>
        <div className="d-f consentContainer">
          <div className="">
            <input id="check" className="checkbox" type="checkbox" />
          </div>
          <div className="consentTxt">
            I consent to the terms and conditions and the privacy policy.
          </div>
        </div>
        <div className="err">{error}</div>
        <div className="submit">{success}</div>
        <div className="btnContainer">
          <Button loading={loading} onClick={submit} title="Request Demo" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModelForm;
