import './App.less'
import './common.less'
import Header from './Component/Header/Header'
import DescBgSec1 from './images/sec1/bgright.png'

import mbDescBgSec1 from './images/sec1/bgrightmb.png'

import WOW from 'wowjs'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from './Component/Button/Button'
import Sec1ARightImg from './images/sec1/rightImg.png'
import Sec2Img from './images/sec2/sec2.gif'
import ListItem from './Component/ListItem/ListItem'

import Sec10Img from './images/sec10/rightimg.png'
import Sec10Img2 from './images/sec10/sec10-2.png'
import Sec10Img3 from './images/sec10/sec10-3.png'
import { useState, useEffect, useRef } from 'react'
import logo from './images/logo.png'

import ServicesItem from './Component/ServicesItem/ServicesItem'
import linkedin from './images/linkedin.png'
import twitter from './images/twitter.png'

import icon1 from './images/sec11/icon1.png'
import icon2 from './images/sec11/icon2.png'
import icon3 from './images/sec11/icon3.png'
import icon4 from './images/sec11/icon4.png'
import icon5 from './images/sec11/icon5.png'
import icon6 from './images/sec11/icon6.png'
import icon7 from './images/sec11/icon7.png'

import db1 from './images/sec3/DB-1.webm'
import db2 from './images/sec3/DB-2.webm'
import db3 from './images/sec3/DB-3.webm'
import db4 from './images/sec3/DB-4.webm'

import db1mp4 from './images/sec3/DB-1.mp4'
import db2mp4 from './images/sec3/DB-2.mp4'
import db3mp4 from './images/sec3/DB-3.mp4'
import db4mp4 from './images/sec3/DB-4.mp4'

import db1gif from './images/sec3/DB-1.gif'
import db2gif from './images/sec3/DB-2.gif'
import db3gif from './images/sec3/DB-3.gif'
import db4gif from './images/sec3/DB-4.gif'

import squad1 from './images/sec5/squad1.webm'
import squad2 from './images/sec5/squad2.webm'

import squad1mp4 from './images/sec5/squad1.mp4'
import squad2mp4 from './images/sec5/squad2.mp4'

import squad1gif from './images/sec5/squad1.gif'
import squad2gif from './images/sec5/squad2.gif'

import user1 from './images/sec7/user1.webm'
import user2 from './images/sec7/user2.webm'
import user3 from './images/sec7/user3.webm'
import user4 from './images/sec7/user4.webm'

import user1mp4 from './images/sec7/user1.mp4'
import user2mp4 from './images/sec7/user2.mp4'
import user3mp4 from './images/sec7/user3.mp4'
import user4mp4 from './images/sec7/user4.mp4'

import user1gif from './images/sec7/user1.gif'
import user2gif from './images/sec7/user2.gif'
import user3gif from './images/sec7/user3.gif'
import user4gif from './images/sec7/user4.gif'

import admin1 from './images/sec8/admin1.webm'
import admin2 from './images/sec8/admin2.webm'
import admin3 from './images/sec8/admin3.webm'
import admin4 from './images/sec8/admin4.webm'

import admin1mp4 from './images/sec8/admin1.mp4'
import admin2mp4 from './images/sec8/admin2.mp4'
import admin3mp4 from './images/sec8/admin3.mp4'
import admin4mp4 from './images/sec8/admin4.mp4'

import admin1gif from './images/sec8/admin1.gif'
import admin2gif from './images/sec8/admin2.gif'
import admin3gif from './images/sec8/admin3.gif'
import admin4gif from './images/sec8/admin4.gif'

import sec4 from './images/sec4/sec4.mp4'
import sec4gif from './images/sec4/sec4.gif'

import fb from './images/fb.png'
import instagram from './images/instagram.png'

import lowest from './images/sec6/lowest.png'
import privacy from './images/sec6/privacy.png'
import network from './images/sec6/network.png'

import sec91 from './images/sec9/1.png'
import sec92 from './images/sec9/2.png'
import sec93 from './images/sec9/3.png'
import sec94 from './images/sec9/4.png'
import 'swiper/swiper-bundle.css'
import Swiper, { Navigation, Pagination, Controller, Autoplay } from 'swiper'

import useWindowDimensions from './useWindowDimensions'
import ModelForm from './Component/Model/Model'
Swiper.use([Navigation, Controller, Pagination, Autoplay])
const sec1ListArr = [
	{
		title: 'User management',
		desc: 'Easily onboard and manage your customers',
		src: db1,
		src2: db1mp4,
		gif: db1gif,
	},
	{
		title: 'Operations',
		desc: 'Process shipments - Mid Mile and 3PL processing',
		src: db2,
		src2: db2mp4,
		gif: db2gif,
	},
	{
		title: 'Generate AWB',
		desc: 'Generate carrier AWB using integrations',
		src: db3,
		src2: db3mp4,
		gif: db3gif,
	},
	{
		title: 'First and Last Mile Management',
		desc: 'Seamlessly assign pickups and deliveries ',
		desc2: 'Get daily driver reports and analytics',
		src: db4,
		src2: db4mp4,
		gif: db4gif,
	},
]

const sec2ListArr = [
	{
		title: 'Route optimization',
		desc: 'Find the most optimized route   ',
		desc2: 'Efficiently assign pickups and deliveries',
		desc3: 'Geolocation',
		desc4: 'Save on fuel costs and delivery time',
		src: squad1,
		src2: squad1mp4,
		gif: squad1gif,
	},
	{
		title: 'First mile pickups and last mile delivery',
		desc: 'Driver mapping ',
		desc2: 'Notification to customers',
		desc3: 'Geolocation',
		src: squad2,
		src2: squad2mp4,
		gif: squad2gif,
	},
]

const sec4ListArr = [
	{
		title: 'Get quick rates',
		desc: 'Your customers can check rates of their shipments',
		src: user1,
		src2: user1mp4,
		gif: user1gif,
	},
	{
		title: 'Place orders',
		desc: 'Your customers can place shipments on your App',
		src: user2,
		src2: user2mp4,
		gif: user2gif,
	},
	{
		title: 'Track shipments',
		desc: 'Your customers can track orders from your App',
		src: user3,
		src2: user3mp4,
		gif: user3gif,
	},
	{
		title: 'Make Payments',
		desc: 'Your customers can view invoices and make payments on your App',
		src: user4,
		src2: user4mp4,
		gif: user4gif,
	},
]

const sec3ListArr = [
	{
		title: 'Scan Shipments',
		desc: 'In-scan and outscan shipments at the warehouse',
		src: admin1,
		src2: admin1mp4,
		gif: admin1gif,
	},
	// {
	// 	title: 'Scan Shipments',
	// 	desc: 'In-scan and outscan shipments at the warehouse',
	// 	src: admin2,
	// 	src2: admin2mp4,
	// },
	{
		title: 'Create Manifest',
		desc: 'Inbound and outbound manifest creation based on carrier connection',
		src: admin3,
		src2: admin3mp4,
		gif: admin1gif,
	},
	{
		title: 'Edit Shipments',
		desc: 'Update shipment details and tracking status',
		src: admin4,
		src2: admin4mp4,
		gif: admin1gif,
	},
]

const arr1 = [
	{ img: icon1 },
	{ img: icon2 },
	{ img: icon3 },

	{ img: icon1 },
	{ img: icon2 },
	{ img: icon3 },

	{ img: icon1 },
	{ img: icon2 },
	{ img: icon3 },
	{ img: icon1 },
	{ img: icon2 },
	{ img: icon3 },

	{ img: icon1 },
	{ img: icon2 },
	{ img: icon3 },

	{ img: icon1 },
	{ img: icon2 },
	{ img: icon3 },
]
const arr2 = [
	{ img: icon4 },
	{ img: icon5 },

	{ img: icon4 },
	{ img: icon5 },

	{ img: icon4 },
	{ img: icon5 },
	{ img: icon4 },
	{ img: icon5 },

	{ img: icon4 },
	{ img: icon5 },

	{ img: icon4 },
	{ img: icon5 },

	{ img: icon4 },
	{ img: icon5 },

	{ img: icon4 },
	{ img: icon5 },

	{ img: icon4 },
	{ img: icon5 },
]
const arr3 = [
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
	{ img: icon6 },
	{ img: icon7 },
]

const merchantLogoList = arr1.map((item, i) => {
	return (
		<div key={i} className='slide slidemarginright'>
			<div className='d-f' style={{ paddingTop: '2em', paddingRight: '3em' }}>
				<div>
					<img src={item.img} className='img-fluid' alt='' />
				</div>
			</div>
		</div>
	)
})

const merchantLogoList2 = arr2.map((item, i) => {
	return (
		<div key={i} className='slide slidemarginright'>
			<div className='d-f' style={{ paddingTop: '2em', paddingRight: '3em' }}>
				<div>
					<img src={item.img} className='img-fluid' alt='' />
				</div>
			</div>
		</div>
	)
})

const merchantLogoList3 = arr3.map((item, i) => {
	return (
		<div key={i} className='slide slidemarginright'>
			<div className='d-f' style={{ paddingTop: '2em', paddingRight: '3em' }}>
				<div>
					<img src={item.img} className='img-fluid' alt='' />
				</div>
			</div>
		</div>
	)
})

const App = () => {
	const { height, width } = useWindowDimensions()

	const [isShown, setShown] = useState(false)

	const [sec3HoverInd, setSec3HoverInd] = useState(0)
	const videoRef = useRef()
	const videoRef2 = useRef()
	const videoRef3 = useRef()
	const videoRef4 = useRef()

	const [sec5HoverInd, setSec5HoverInd] = useState(0)
	const videoRef5 = useRef()
	const videoRef6 = useRef()
	const [sec5Video, setSec5Video] = useState(squad1)

	const [sec7HoverInd, setSec7HoverInd] = useState(0)
	const [sec7Video, setSec7Video] = useState(user1)

	const [sec8HoverInd, setSec8HoverInd] = useState(0)
	const [sec8Video, setSec8Video] = useState(admin1)

	const videoRef8 = useRef()
	const videoRef9 = useRef()
	const videoRef10 = useRef()
	const videoRef11 = useRef()

	const videoRef12 = useRef()
	const videoRef13 = useRef()
	const videoRef14 = useRef()
	const videoRef15 = useRef()

	useEffect(() => {
		new WOW.WOW({
			live: false,
		}).init()

		const sec10swiper = new Swiper('.sec10-swiper', {
			slidesPerView: 1,
			loop: true,

			autoplay: {
				delay: 5000,
			},
			grabCursor: true,

			pagination: {
				el: '.sec10 .swiper-pagination',
				clickable: true,
			},
		})

		const mbsec3swiper = new Swiper('.mbsec3-swiper', {
			slidesPerView: 1,
			loop: true,

			autoplay: {
				delay: 5000,
			},
			grabCursor: true,

			pagination: {
				el: '.mbsec3-swiper .swiper-pagination',
				clickable: true,
			},
		})

		const mbsec5swiper = new Swiper('.mbsec5-swiper', {
			slidesPerView: 1,
			loop: true,

			autoplay: {
				delay: 5000,
			},
			grabCursor: true,

			pagination: {
				el: '.mbsec5-swiper .swiper-pagination',
				clickable: true,
			},
		})

		const mbsec8swiper = new Swiper('.mbsec8-swiper', {
			slidesPerView: 1,
			loop: true,

			autoplay: {
				delay: 5000,
			},
			grabCursor: true,

			pagination: {
				el: '.mbsec8-swiper .swiper-pagination',
				clickable: true,
			},
		})
	}, [])

	const sec1MouseEnter = (e, i) => {
		e.preventDefault()
		setSec3HoverInd(i)
		videoRef.current?.restart()
		videoRef2.current?.restart()
		videoRef3.current?.restart()
		videoRef4.current?.restart()
	}

	const sec2MouseEnter = (e, i) => {
		e.preventDefault()
		setSec5HoverInd(i)
		videoRef5.current?.restart()
		videoRef6.current?.restart()
	}

	const sec3MouseEnter = (e, i) => {
		e.preventDefault()
		setSec7HoverInd(i)

		videoRef8.current?.restart()
		videoRef9.current?.restart()
		videoRef10.current?.restart()
		videoRef11.current?.restart()
	}

	const sec8MouseEnter = (e, i) => {
		e.preventDefault()
		setSec8HoverInd(i)
		videoRef12.current?.restart()
		videoRef13.current?.restart()
		videoRef14.current?.restart()
		videoRef15.current?.restart()
	}

	const scrollToProduct = () => {
		const id = 'prooduct'
		const yOffset = -70
		const element = document.getElementById(id)
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

		window.scrollTo({ top: y, behavior: 'smooth' })
	}
	const scrollToHome = () => {
		const id = 'home'
		const yOffset = -70
		const element = document.getElementById(id)
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

		window.scrollTo({ top: y, behavior: 'smooth' })
	}

	const modelShow = () => {
		setShown(true)
	}

	return (
		<>
			<Header
				showModel={modelShow}
				homeBtnClick={scrollToHome}
				productBtnClick={scrollToProduct}
			/>
			<img className='bgDesc img-fluid' src={DescBgSec1} />
			<section id='home' className='sec1 '>
				<Row className='m-0'>
					<Col className='p-0' sm={12} xs={12} lg={6} md={6}>
						<div className='d-f f-c firstContainer'>
							<div className='getOlText'>Get online within 48 hours!</div>
							<div className='desc'>
								Speedbox provides white labeled technology solutions enabling
								door to door services for logistics companies.
								<br></br>
								<br></br>
								<span>We get offline logistics companies, Online!</span>
							</div>
							<div className='buttonContainer'>
								<Button onClick={modelShow} title='Learn More' />
							</div>
						</div>
					</Col>

					<Col
						style={{ zIndex: '33' }}
						className='p-0 '
						sm={12}
						xs={12}
						lg={6}
						md={6}
					>
						<div className='rightSideImgContainer'>
							<img className='img-fluid' src={Sec1ARightImg} />
						</div>
					</Col>
				</Row>
			</section>
			<div className='divider'></div>
			<section className='sec2 wow fadeIn d-f f-c'>
				<div className='imgContainer'>
					<img className='img-fluid' src={Sec2Img} />
				</div>
			</section>
			{width > 1023 && <div className='divider'></div>}
			<section id='prooduct' className='sec3 wow fadeIn d-f f-c'>
				<div className='greyline'></div>
				<div className='title'>What we Offer!</div>

				{width < 1023 ? (
					<div className='mbcontainer '>
						<div className='titleContainer'>
							<div className='title2'>Web dashboard</div>
						</div>
						<div className='d-f f-c firstContainer'>
							<div className='textControlTxt'>
								Take control of your business!
							</div>
							<div className='desc'>
								Manage entire operations and streamline processes through the
								Speedbox dashboard
							</div>
						</div>

						<div className='imgContainer mbsec3-swiper swiper-containe '>
							<div className='swiper-wrapper'>
								{sec1ListArr.map((item, i) => {
									return (
										<ListItem
											mobile={true}
											gif={item.gif}
											title={item.title}
											desc={item.desc}
											src2={item.src2}
											desc2={item.desc2}
										/>
									)
								})}
							</div>
							<div className='d-f j-c '>
								<div className='swiper-pagination'></div>
							</div>
						</div>
					</div>
				) : (
					<Row className='m-0 container'>
						<Col className='p-0' lg={6}>
							<div className='titleContainer'>
								<div className='title2'>Web dashboard</div>
							</div>
							<div className='d-f f-c firstContainer'>
								<div className='textControlTxt'>
									Take control of your business!
								</div>
								<div className='desc'>
									Manage entire operations and streamline processes through the
									Speedbox dashboard and adminApp
								</div>

								<div className='listContainer'>
									{sec1ListArr.map((item, i) => {
										return (
											<ListItem
												onMouseEnter={(e) => sec1MouseEnter(e, i)}
												selected={i == sec3HoverInd ? true : false}
												title={item.title}
												desc={item.desc}
												desc2={item.desc2}
											/>
										)
									})}
								</div>

								<div className='buttonContainer'>
									<Button onClick={modelShow} title='Learn More' />
								</div>
							</div>
						</Col>

						<Col className='p-0 ' lg={6}>
							<div className='imgContainer'>
								<div className={`common ${sec3HoverInd === 0 ? 'active' : ''}`}>
									<video ref={videoRef} key='1' muted autoPlay loop>
										<source src={db1mp4} type='video/mp4'></source>
										<source src={db1} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec3HoverInd === 1 ? 'active' : ''}`}>
									<video ref={videoRef2} key='2' muted autoPlay loop>
										<source src={db2mp4} type='video/mp4'></source>
										<source src={db2} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec3HoverInd === 2 ? 'active' : ''}`}>
									<video ref={videoRef3} key='3' muted autoPlay loop>
										<source src={db3mp4} type='video/mp4'></source>
										<source src={db3} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec3HoverInd === 3 ? 'active' : ''}`}>
									<video ref={videoRef4} key='3' muted autoPlay loop>
										<source src={db4mp4} type='video/mp4'></source>
										<source src={db4} alt='img' type='video/webm'></source>
									</video>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</section>
			{width > 1023 && <div className='divider'></div>}
			<section className='sec4  d-f f-c'>
				<div>
					<div className='title'>it’s all about automation!</div>
					<div className='desc'>
						Make use of carrier integrations, e-commerce plugins and free APIs
						to access features like Airwaybill generation, automated tracking,
						rate finder, shipment booking and much more.
					</div>
				</div>
				<div className='imgContainer'>
					{width < 1023 ? (
						<img src={sec4gif} className='img-fluid' alt='' />
					) : (
						<video muted autoPlay loop>
							<source src={sec4} alt='img' type='video/mp4'></source>
						</video>
					)}
				</div>
			</section>
			{width > 1023 && <div className='divider'></div>}
			<section className='sec5 wow fadeIn d-f f-c c-r'>
				{width < 1023 ? (
					<div className='mbcontainer '>
						<div className='titleContainer2'>
							<div className='title2'>Squad App</div>
						</div>
						<div className='d-f f-c firstContainer'>
							<div className='textControlTxt'>Be in the driver’s seat!</div>
							<div className='desc'>
								Seamlessly manage pickups and deliveries with the Squad App
							</div>
						</div>

						<div className='imgContainer mbsec5-swiper swiper-containe '>
							<div className='swiper-wrapper'>
								{sec2ListArr.map((item, i) => {
									return (
										<ListItem
											mobile={true}
											src={item.src}
											title={item.title}
											desc={item.desc}
											desc2={item.desc2}
											desc3={item.desc3}
											desc4={item.desc4}
											src2={item.src2}
											gif={item.gif}
										/>
									)
								})}
							</div>
							<div className='d-f j-c '>
								<div className='swiper-pagination'></div>
							</div>
						</div>
					</div>
				) : (
					<Row className='m-0 container'>
						<Col className='p-0 col1' lg={6}>
							<div className='titleContainer'>
								<div className='title2'>Squad App</div>
							</div>
							<div className='d-f f-c firstContainer'>
								<div className='textControlTxt'>Be in the driver’s seat!</div>
								<div className='desc'>
									Seamlessly manage pickups and deliveries with the Squad App
								</div>

								<div className='listContainer'>
									{sec2ListArr.map((item, i) => {
										return (
											<ListItem
												right={true}
												onMouseEnter={(e) => sec2MouseEnter(e, i)}
												selected={i == sec5HoverInd ? true : false}
												title={item.title}
												desc={item.desc}
												desc2={item.desc2}
												desc3={item.desc3}
												desc4={item.desc4}
											/>
										)
									})}
								</div>

								<div className='buttonContainer'>
									<Button onClick={modelShow} title='Learn More' />
								</div>
							</div>
						</Col>

						<Col className='p-0 ' lg={6}>
							<div className='imgContainer'>
								<div className={`common ${sec5HoverInd === 0 ? 'active' : ''}`}>
									<video ref={videoRef5} key='5' muted autoPlay loop>
										<source src={squad1} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec5HoverInd === 1 ? 'active' : ''}`}>
									<video ref={videoRef6} key='7' muted autoPlay loop>
										<source src={squad2} alt='img' type='video/webm'></source>
									</video>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</section>
			{width > 1023 && <div className='divider'></div>}
			<section className='sec6 wow fadeIn d-f f-c'>
				<div className='listContainer d-f jc-sa a-c'>
					<ServicesItem fromSec3={true} img={lowest} title='Increase Sales' />
					<ServicesItem fromSec3={true} img={privacy} title='Reduce Manpower' />
					<ServicesItem
						fromSec3={true}
						img={network}
						title='Reduce Email Trails'
					/>
				</div>
			</section>
			{width > 1023 && <div className='divider'></div>}
			<section className='sec3 sec7 wow fadeIn d-f f-c'>
				{width < 1023 ? (
					<div className='mbcontainer '>
						<div className='titleContainer'>
							<div className='title2'>user APP</div>
						</div>
						<div className='d-f f-c firstContainer'>
							<div className='textControlTxt'>The Power of Branding!</div>
							<div className='desc'>
								Get your Company’s own white label app - Let your shippers
								download your app from iOS and Android.
							</div>
						</div>

						<div className='imgContainer mbsec3-swiper swiper-containe f-c'>
							<div className='swiper-wrapper'>
								{sec4ListArr.map((item, i) => {
									return (
										<ListItem
											mobile={true}
											src={item.src}
											title={item.title}
											desc={item.desc}
											src2={item.src2}
											gif={item.gif}
										/>
									)
								})}
							</div>
							<div className='d-f j-c '>
								<div className='swiper-pagination'></div>
							</div>
						</div>
					</div>
				) : (
					<Row className='m-0 container'>
						<Col className='p-0' lg={6}>
							<div className='titleContainer'>
								<div className='title2'>user APP</div>
							</div>
							<div className='d-f f-c firstContainer'>
								<div className='textControlTxt'>The Power of Branding!</div>
								<div className='desc'>
									Get your Company’s own white label app - Let your shippers
									download your app from iOS and Android.
								</div>

								<div className='listContainer'>
									{sec4ListArr.map((item, i) => {
										return (
											<ListItem
												onMouseEnter={(e) => sec3MouseEnter(e, i)}
												selected={i == sec7HoverInd ? true : false}
												title={item.title}
												desc={item.desc}
											/>
										)
									})}
								</div>

								<div className='buttonContainer'>
									<Button onClick={modelShow} title='Learn More' />
								</div>
							</div>
						</Col>

						<Col className='p-0 ' lg={6}>
							<div style={{ paddingLeft: '5em' }} className='imgContainer'>
								<div className={`common ${sec7HoverInd === 0 ? 'active' : ''}`}>
									<video ref={videoRef8} key='5' muted autoPlay loop>
										<source src={user1} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec7HoverInd === 1 ? 'active' : ''}`}>
									<video ref={videoRef9} key='7' muted autoPlay loop>
										<source src={user2} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec7HoverInd === 2 ? 'active' : ''}`}>
									<video ref={videoRef10} key='5' muted autoPlay loop>
										<source src={user3} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec7HoverInd === 3 ? 'active' : ''}`}>
									<video ref={videoRef11} key='7' muted autoPlay loop>
										<source src={user4} alt='img' type='video/webm'></source>
									</video>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</section>
			{width > 1023 && <div className='divider'></div>}
			{/* <section className='sec7a d-f wow fadeIn jc-sb'>
				<div className='d-f f-c'>
					<div className='title'>The Power of Branding!</div>
					<div className='desc '>
						Our white label solution enables shippers to download your app on
						iOS and Android.
					</div>
				</div>
				<div className='buttonContainer'>
					<Button onClick={modelShow} title='Learn More' />
				</div>
			</section> */}
			<section className='sec8 d-f wow fadeIn f-c c-r'>
				{width < 1023 ? (
					<div className='mbcontainer '>
						<div className='titleContainer2'>
							<div className='title2'>Admin APP</div>
						</div>
						<div className='d-f f-c firstContainer'>
							<div className='textControlTxt'>Never miss out!</div>
							<div className='desc'>
								Keep a track of shipments coming in and out of your warehouse
							</div>
						</div>

						<div className='imgContainer mbsec8-swiper swiper-containe f-c'>
							<div className='swiper-wrapper'>
								{sec3ListArr.map((item, i) => {
									return (
										<ListItem
											mobile={true}
											src={item.src}
											title={item.title}
											desc={item.desc}
											src2={item.src2}
											gif={item.gif}
										/>
									)
								})}
							</div>
							<div className='d-f j-c '>
								<div className='swiper-pagination'></div>
							</div>
						</div>
					</div>
				) : (
					<Row className='m-0 container'>
						<Col className='p-0 col1' lg={6}>
							<div className='titleContainer'>
								<div className='title2'>Admin App</div>
							</div>
							<div className='d-f f-c firstContainer'>
								<div className='textControlTxt'>Never miss out!</div>
								<div className='desc'>
									Keep a track of shipments coming in and out of your warehouse
								</div>

								<div className='listContainer'>
									{sec3ListArr.map((item, i) => {
										return (
											<ListItem
												right={true}
												onMouseEnter={(e) => sec8MouseEnter(e, i)}
												selected={i == sec8HoverInd ? true : false}
												title={item.title}
												desc={item.desc}
											/>
										)
									})}
								</div>

								<div className='buttonContainer'>
									<Button onClick={modelShow} title='Learn More' />
								</div>
							</div>
						</Col>

						<Col className='p-0 ' lg={6}>
							<div className='imgContainer'>
								<div className={`common ${sec8HoverInd === 0 ? 'active' : ''}`}>
									<video ref={videoRef12} key='5' muted autoPlay loop>
										<source src={admin1} alt='img' type='video/webm'></source>
									</video>
								</div>
								{/* <div className={`common ${sec8HoverInd === 1 ? 'active' : ''}`}>
									<video ref={videoRef13} key='7' muted autoPlay loop>
										<source src={admin2} alt='img' type='video/webm'></source>
									</video>
								</div> */}
								<div className={`common ${sec8HoverInd === 1 ? 'active' : ''}`}>
									<video ref={videoRef14} key='7' muted autoPlay loop>
										<source src={admin3} alt='img' type='video/webm'></source>
									</video>
								</div>
								<div className={`common ${sec8HoverInd === 2 ? 'active' : ''}`}>
									<video ref={videoRef15} key='7' muted autoPlay loop>
										<source src={admin4} alt='img' type='video/webm'></source>
									</video>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</section>
			{width > 1023 && <div className='divider'></div>}
			<section className='sec9 d-f wow fadeIn f-c'>
				<div className='title'>Our Benefits</div>
				<div className='listContainer'>
					<ServicesItem
						img={sec91}
						title='Increase operational efficiency'
						desc='Built Wicket longer admire do barton vanity itself do in it.'
					/>
					<ServicesItem
						img={sec92}
						title='Scale up your customer base'
						desc='Built Wicket longer admire do barton vanity itself do in it.'
					/>
					<ServicesItem
						img={sec93}
						title='Add transparency and visibility'
						desc='Built Wicket longer admire do barton vanity itself do in it.'
					/>
					<ServicesItem
						img={sec94}
						title='Data privacy and ownership'
						desc='Built Wicket longer admire do barton vanity itself do in it.'
					/>
				</div>
			</section>

			<section className='sec10 d-f wow fadeIn f-c'>
				<div className='imgContainer sec10-swiper swiper-container'>
					<div className='swiper-wrapper'>
						<div className='swiper-slide'>
							<img className='img-fluid' src={Sec10Img} />
						</div>
						<div className='swiper-slide'>
							<img className='img-fluid' src={Sec10Img2} />
						</div>
						<div className='swiper-slide'>
							<img className='img-fluid' src={Sec10Img3} />
						</div>
					</div>
					<div className='d-f j-c'>
						<div className='swiper-pagination'></div>
					</div>
				</div>
			</section>

			<section className='sec11 wow fadeIn d-f f-c'>
				<div className='slider'>
					<div className='slide-track'>{merchantLogoList}</div>
					<div className='slide-track'>{merchantLogoList2}</div>
					<div className='slide-track'>{merchantLogoList3}</div>
				</div>
			</section>

			<section className='sec12 wow fadeIn'>
				<div className='container'>
					<div className='title'>
						Subscribe to get information, latest news and other interesting
						offers about Speedbox
					</div>
					<div className='d-if j-c a-c subscribeContainer'>
						<div className='d-f emailContainer'>
							<div className='emailIcon'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'>
									<path
										fill='#222'
										d='M53.42 53.32H10.58a8.51 8.51 0 0 1-8.5-8.5V19.18a8.51 8.51 0 0 1 8.5-8.5h42.84a8.51 8.51 0 0 1 8.5 8.5v25.64a8.51 8.51 0 0 1-8.5 8.5ZM10.58 13.68a5.5 5.5 0 0 0-5.5 5.5v25.64a5.5 5.5 0 0 0 5.5 5.5h42.84a5.5 5.5 0 0 0 5.5-5.5V19.18a5.5 5.5 0 0 0-5.5-5.5Z'
									/>
									<path
										fill='#222'
										d='M32 38.08a8.51 8.51 0 0 1-5.13-1.71L3.52 18.71a1.5 1.5 0 1 1 1.81-2.39L28.68 34a5.55 5.55 0 0 0 6.64 0l23.35-17.68a1.5 1.5 0 1 1 1.81 2.39L37.13 36.37A8.51 8.51 0 0 1 32 38.08Z'
									/>
									<path
										fill='#222'
										d='M4.17 49.14a1.5 1.5 0 0 1-1-2.62l18.4-16.41a1.5 1.5 0 0 1 2 2.24L5.17 48.76a1.46 1.46 0 0 1-1 .38zm55.66 0a1.46 1.46 0 0 1-1-.38l-18.4-16.41a1.5 1.5 0 1 1 2-2.24l18.39 16.41a1.5 1.5 0 0 1-1 2.62z'
									/>
								</svg>
							</div>
							<input type='mail' placeholder='Your email'></input>
						</div>
						<div className='buttonContainer'>
							<Button onClick={modelShow} title='Subscribe' />
						</div>
					</div>
					<div className='logoContainer'>
						<img src={logo} className='img-fluid' alt='Speedbox' />
					</div>
					<Row className='linkContainer'>
						<Col lg={12} md={12} xs={12}>
							<div className='d-f f-c'>
								<div className='socialIconContainer d-f a-c j-c'>
									<div
										onClick={() => {
											window.open(
												'https://www.facebook.com/speedboxlogistics/',
												'_blank'
											)
										}}
										className='icon'
									>
										<img
											src={fb}
											className='img-fluid'
											width='40'
											height='40'
										/>
									</div>
									<div
										onClick={() => {
											window.open(
												'https://www.linkedin.com/company/speedbox/?originalSubdomain=in',
												'_blank'
											)
										}}
										className='icon'
									>
										<img
											src={linkedin}
											className='img-fluid'
											width='40'
											height='40'
										/>
									</div>
									<div
										onClick={() => {
											window.open(
												'https://twitter.com/SpeedboxMaster',
												'_blank'
											)
										}}
										className='icon'
									>
										<img
											src={twitter}
											className='img-fluid'
											width='40'
											height='40'
										/>
									</div>
								</div>
								<div className='discoverTxt'>Follow Us</div>
								<div className='d-f jc-sb termsConditonContainer'>
									<div className='d-f f-c'>
										<div>Copyright © 2022-2023 SpeedboxTM Inc.</div>
										<div>All Rights Reserved</div>
									</div>
									<div className='d-f'>
										<a
											style={{ color: 'black' }}
											target='_blank'
											href='https://www.speedboxapp.com/termsofuse'
										>
											Terms & Conditions
										</a>
										<div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
										<a
											style={{ color: 'black' }}
											target='_blank'
											href='https://www.speedboxapp.com/privacy-policy'
										>
											Privacy Policy
										</a>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</section>
			<ModelForm show={isShown} hide={() => setShown(false)} />
		</>
	)
}

export default App
