import React from 'react'
import './style.less'

const ServicesItem = (props) => {
	//console.log("==>", props.fournotfonund);

	if (props.fromSec3) {
		return (
			<div className='Sec3itemContainer'>
				<div className='imgContainer'>
					<img className='img-fluid' src={props.img}></img>
				</div>
				<div className='itemTitle'>{props.title}</div>
				<div className='itemDesc'>{props.desc}</div>
			</div>
		)
	}

	return (
		<div className='ServiceitemContainer'>
			<div className='imgContainer'>
				<img className='img-fluid' src={props.img}></img>
			</div>
			<div className='itemTitle'>{props.title}</div>
		</div>
	)
}

export default React.memo(ServicesItem)
